import React from 'react';
import AppRouter from './components/routes/AppRouter';


function App() {
  return (
      <AppRouter />
  );
}

export default App;
