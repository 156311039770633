//appReducer
export const USER_ERRORS = "USER_ERRORS";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const SORT_BY_NAME = "SORT_BY_NAME";
export const SORT_BY_PRICE = "SORT_BY_PRICE";
export const FILTER_BY_CATEGORY = "FILTER_BY_CATEGORY";
export const GET_DETAILS = "GET_DETAILS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const ADD_ORDER_ID = "ADD_ORDER_ID";
export const GET_ALL_REVIEWS = "GET_ALL_REVIEWS";
export const REMOVE_ALL_REVIEWS = "REMOVE_ALL_REVIEWS";
export const GET_ALL_FAVORITES = "GET_ALL_FAVORITES"
export const ADD_FAVORITE = "ADD_FAVORITE"
export const DELETE_FAVORITE = "DELETE_FAVORITE"
export const REMOVE_ALL_FAVORITES = "REMOVE_ALL_FAVORITES"
export const SORT_BY_NAME_FAVORITES = "SORT_BY_NAME_FAVORITES";
export const SORT_BY_PRICE_FAVORITES = "SORT_BY_PRICE_FAVORITES";
export const FILTER_BY_CATEGORY_FAVORITES = "SORT_BY_PRICE_FAVORITES";
export const SAVE_USER = "SAVE_USER";
export const CLEAN_USER = "CLEAN_USER";
export const CLEAN_USER_CART = "CLEAN_USER_CART";
export const CLEAR_APP = "CLEAR_APP";

//shoppingCartReducer
export const LINK_USER_CART = 'LINK_USER_CART'
export const ITEMS_DB_TO_CART = 'ITEMS_DB_TO_CART'
export const ADD_TO_CART = "ADD_TO_CART";
export const DELETE_ONE_ITEM = "DELETE_ONE_ITEM";
export const CLEAN_CART = "CLEAN_CART";
export const BUY_CART = "BUY_CART";
export const DECREMENT_QUANTITY_ITEM = "DECREMENT_QUANTITY_ITEM";

//barra de busqueda
export const SET_SEARCHBAR = "SET_SEARCHBAR";
export const ADD_USER = "ADD_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
